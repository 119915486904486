import React from 'react'
import { Button, Card, ProgressBar, Stack } from 'react-bootstrap'
import { currencyFormatter } from '../utils'


export default function BudgetCard({ name, amount, max, gray, onAddExpenseClick, hideButtons, onViewExpenseClick }) {
    const classNames = []
    if (amount > max) {
        classNames.push("bg-danger", "bg-opacity-10")
    } else if (gray) {
        classNames.push("bg-light")
    }

  return (
    <Card className={classNames.join(" ")}>
        <Card.Body>
            <Card.Title className='d-flex justify-content-between align-items-baseline fw-normal mb-3'>
                <div className='me-2'>{name}</div>
                <div className='d-felx align-items-baseline'>
                    {currencyFormatter.format(amount)}
                    {max && 
                        <span className='text-muted fs-6 ms-1'> 
                            / {currencyFormatter.format(max)}
                        </span>
                    }
                </div>
            </Card.Title>
            {max &&
                <ProgressBar
                    className='rounded-pill'
                    variant={getProgressBarVariant(amount, max)}
                    min={0}
                    max={max}
                    now={amount}
                />
            }
            {!hideButtons && 
                <Stack direction='horizontal' gap="2" className='mt-4'>
                    <Button variant='outline-primary ms-auto' className='ms-auto' onClick={onAddExpenseClick}>Add Expense</Button>
                    <Button variant='outline-secondary' onClick={onViewExpenseClick}>View Expenses</Button>
                </Stack>
            }
        </Card.Body>
    </Card>
  )
}

function getProgressBarVariant(amount, max){
    const ratio = amount / max
    return (ratio < 0.5) ? "primary" : (ratio < 0.75) ? "warning" : "danger"
}